
import { PackageViewMode } from '@/common/enums/PackageViewMode.enum';
import QuantityTicker from '@/common/components/quantity-ticker.component.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { Time } from '@/common/models/Time.model';
import { Item } from '@/common/models/Item.model';
import Currency from '@/common/components/currency.component.vue';
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';
import ActivitySelector from '../components/activity-selector.component.vue';
import { OrderEntityTimeAddOn } from '@/common/models/OrderEntityTime.model';

@Options({
	name: 'AddonView',
	components: { QuantityTicker, Currency, ActivitySelector },
})
export default class AddonView extends Vue {
	@Prop({ default: '0' }) clientLocationKey!: string;
	@Prop({ default: '0' }) type!: PackageViewMode;
	@Prop({ default: '0' }) typekey!: string;
	variantModal: OrderEntityTimeAddOn | null = null;
	infoModal: OrderEntityTimeAddOn | null = null;
	created() {
		if (orderModule.itemActivities.length == 0) {
			return;
		}
		orderModule.setOpenActivity(orderModule.itemActivities[0].entityHierarchyKey);
		orderModule.openActivity?.getActivityOptions();
	}
	get modalTitle() {
		return this.infoModal?.AddOnName;
	}
	get activity(): OrderActivity | null {
		return orderModule.openActivity;
	}
	get isPackage(): boolean {
		return !!this.item?.PackageKey;
	}
	get item(): Item | null {
		return orderModule.openItem;
	}
	get addons(): OrderEntityTimeAddOn[] {
		return orderModule.openActivity?.selectedTimes[0]?.AddOns ?? [];
	}
	get canProceed(): boolean {
		return true;
	}
	next(): void {
		if (orderModule.openActivity) {
			orderModule.openActivity.addons = orderModule.openActivity?.selectedTimes[0]?.AddOns ?? [];
		}
		let openKey = this.activity?.entityHierarchyKey ?? 0;
		let activities = orderModule.itemActivities.filter((a) => a.activity.AddOns.length);
		let idx = activities.findIndex((a) => a.entityHierarchyKey == openKey && a.activity.AddOns.length);
		let next = idx >= 0 ? activities[idx + 1] : false;
		if (next) {
			orderModule.setOpenActivity(next.entityHierarchyKey);
			orderModule.openActivity?.getActivityOptions();
		} else {
			this.$router.push(`/portal/${this.clientLocationKey}/${this.type}/${this.typekey}/book/checkout`);
		}
	}
	isSelected(addon: OrderEntityTimeAddOn): boolean {
		if (addon.Variants.length) {
			return !!addon.Variants.find((a) => a.Quantity > 0);
		}
		return addon.Quantity > 0;
	}
	select(addon: OrderEntityTimeAddOn): void {
		if (addon.IsBoolean) {
			addon.Quantity = addon.AddOnIsRequired ? 1 : addon.Quantity ? 0 : 1;
		} else if (addon.Variants.length) {
			this.variantModal = JSON.parse(JSON.stringify(addon));
		} else {
			addon.Quantity = (addon.Quantity ?? 0) + 1;
		}
		console.log(addon);
	}
	get selectedTimes(): Time[] {
		return this.activity?.selectedTimes ?? [];
	}
	commitVariants() {
		if (this.variantModal) {
			let idx = this.addons.findIndex((i) => i.ProductKey == this.variantModal!.ProductKey);
			this.addons[idx] = this.variantModal;
		}
		this.variantModal = null;
	}
	getThumb(addon: OrderEntityTimeAddOn): string | null {
		if (!addon.PhotoDomain || !addon.PhotoVersion || !addon.PhotoFolder) {
			return null;
		}
		return addon.PhotoDomain + 'w_225,h_175,c_fill' + '/v' + addon.PhotoVersion + addon.PhotoFolder.replace('/dev/', '/prod/') + '/original';
	}
}
